<template>
    <div v-if="isAllowedTo('SupplierCentral/listSalesPriceTasks')" >
        <mercur-card class="template-tasks-overview fill full-height-layout mb-3 mx-4">
            <grid-header :big-headline="true">
                Pricing Rule Template tasks<span v-if="pricingRuleTemplateName"> for {{ pricingRuleTemplateName }}</span>
                <template slot="actions">
                    <mercur-button
                        class="btn btn-raised"
                        :to="getLogsRoute()"
                    ><i class="fas fa-file-alt"></i> Show logs</mercur-button>
                </template>
            </grid-header>

            <div class="border fill full-height-layout">
                <data-table
                    class="fill full-height-layout border"
                    ref="grid"
                    :options="options"
                    :url="url"
                    data-test="pricingRuleTemplatesTable"
                ></data-table>
            </div>
        </mercur-card>

        <portal to="sidebarRight">
            <transition name="slide-fade" @after-leave="onSidebarHidden">
                <router-view
                    ref="routeview"
                    class="sidebarRight"
                    :template-name="pricingRuleTemplateName"
                    :parent-route="overviewRoute"
                ></router-view>
            </transition>
        </portal>

    </div>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>
<script>
import PricingHelperMethods from '../../components/pricing/PricingHelperMethods'
import CONFIG from '@root/config'
import GridHeader from '../../components/GridHeader'
import DataTable from '../../components/DataTable'

export default {
    name: 'TemplateRulesTasksOverview',
    mixins: [PricingHelperMethods],
    components: { DataTable, GridHeader },
    props: {
        templateName: {
            default: null,
        },
        pricingRuleTemplateId: {
            required: true,
        },
        agreementId: {
            required: true,
        },
    },
    data () {
        return {
            url: CONFIG.API.ROUTES.PRICING.TASKS.LIST,
            loading: false,
            products: null,
            pricingRuleTemplate: null,
            options: {
                columns: {
                    'pricingRuleTemplateId': {
                        field: 'pricingRuleTemplateId',
                        hide: true,
                    },
                    'Template name': {
                        field: 'product',
                        sortable: true,
                        valueGetter: ({ data }) => {
                            if (this.products) {
                                const product = this.products.find(product => product.productConfigurationTemplateId === data.productTemplateId)

                                if (product) {
                                    return product.productName
                                }
                            }
                            return data.productTemplateId
                        },
                        filter: 'agTextColumnFilter',
                        filterParams: {
                            filterOptions: ['contains'],
                        },
                    },
                    'Countries': {
                        field: 'countries',
                        sortable: true,
                        valueGetter: ({ data }) => {
                            const cacheData = JSON.parse(data.cacheData)
                            return cacheData.countries
                        },
                        filter: 'agTextColumnFilter',
                        filterParams: {
                            filterOptions: ['contains'],
                        },
                    },
                    'Status': {
                        field: 'taskStatus',
                        statusChip: true,
                        sortable: true,
                        valueGetter: ({ data }) => {
                            const cacheData = JSON.parse(data.cacheData)
                            if (cacheData.handledSkus && cacheData.skuCount && data.taskStatus === 'RUNNING_CACHING') {
                                const progress = (cacheData.handledSkus / cacheData.skuCount) * 100
                                return data.taskStatus + ' ' + progress.toFixed(2) + '%'
                            }
                            return data.taskStatus
                        },
                        filter: 'agTextColumnFilter',
                        filterParams: {
                            filterOptions: ['contains'],
                        },
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                    },
                    'Product Hash': {
                        field: 'productHash',
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        filterParams: {
                            filterOptions: ['contains'],
                        },
                    },
                    'Task type': {
                        statusChip: true,
                        field: 'taskType',
                        filterParams: {
                            filterOptions: ['contains'],
                        },
                    },
                },
                filterModel: {
                    pricingRuleTemplateId: {
                        filter: this.pricingRuleTemplateId,
                        filterType: 'text',
                        type: 'equals',
                    },
                },
                sortModel: [
                    {
                        colId: 'dateUpdated',
                        sort: 'desc',
                    },
                ],
                actions: [
                    {
                        icon: 'fas fa-file-alt',
                        isIcon: true,
                        text: 'Show logs',
                        to: ({ data }) => this.getLogsRoute(data, true),
                    },
                ],
                paginationPageSize: 25,
                cacheBlockSize: 25,
            },
        }
    },
    computed: {
        overviewRoute () {
            return {
                name: 'TemplateTasksOverview',
                params: {
                    agreementId: this.agreementId,
                    pricingRuleTemplateId: this.pricingRuleTemplateId,
                },
            }
        },
        pricingRuleTemplateName () {
            if (!this.pricingRuleTemplate) {
                return null
            }
            return this.pricingRuleTemplate.templateName
        },
    },
    methods: {
        getLogsRoute (data, showSingleTemplateLogs) {
            return {
                name: 'TemplateLogs',
                params: {
                    ...this.$route.params,
                    agreementId: this.agreementId,
                    pricingRuleTemplateId: this.pricingRuleTemplateId,
                    salesPriceTaskId: data ? data.salesPriceTaskId : '',
                    showSingleTemplateLogs: showSingleTemplateLogs,
                },
            }
        },
        getProducts () {
            if (!this.isAllowedTo('getAgreementProductsByAgreementId')) {
                return
            }
            const url = CONFIG.API.ROUTES.AGREEMENTS.GET_PRODUCTS.replace('{agreementId}', this.agreementId)
            const requestPayload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))

            this.addJob(url)
            this.items = null
            this.$api.post(url, requestPayload).then(({ data }) => {
                this.products = data.data.items
                this.$nextTick(() => {
                    try {
                        this.$refs.grid.gridApi.redrawRows()
                    } catch (e) { }
                })
            }).catch(err => {
                this.$root.$emit('notification:global', {
                    message: err,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(url)
            })
        },
        onSidebarHidden () {
            this.$bus.$emit('sidebar-hidden')
        },
    },
    watch: {
        pricingRuleTemplateId: {
            handler () {
                const url = CONFIG.API.ROUTES.PRICING.PRICING_RULE_TEMPLATES.LIST.replace('{agreementId}', this.agreementId)
                const requestPayload = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))

                this.addJob(url)

                this.$api.post(url, requestPayload).then(({ data }) => {
                    this.pricingRuleTemplate = data.data.items.find(({ pricingRuleTemplateId }) => pricingRuleTemplateId === this.pricingRuleTemplateId)
                }).catch(err => {
                    this.$root.$emit('notification:global', {
                        message: err,
                        type: 'error',
                    })
                }).finally(() => {
                    this.finishJob(url)
                })
            },
            immediate: true,
        },
    },
    created () {
        this.getProducts()
    },
}
</script>

<style lang="scss" scoped>
.template-tasks-overview {
    .fas {
        font-size: 14px !important;
    }
}
</style>
